import {Controller} from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
    static targets = ['fileInput', 'searchInput'];
    static values = {
        identifier: String,
    }

    selectImage(event) {
        event.preventDefault();

        if (this.hasIdentifierValue) {
            EventManager.fire('image-gallery.select-image', {
                path: event.currentTarget.dataset.imageUrl,
                identifier: this.identifierValue
            });
        }
    }

    selectFile() {
        this.fileInputTarget.click();
    }

    uploadFile() {
        let file = this.fileInputTarget.files[0];

        if (!file) {
            return;
        }

        let formData = new FormData();
        formData.append("file", file);

        axios.post('/admin/files/image/create?flash=0', formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(() => axios.get('/admin/image-gallery-component/images').then(response => {
                // this.element.querySelector('#image-browse-list').innerHTML = response.data;
                // this.searchInputTarget.value = '';
                this.element.innerHTML = response.data;
            }))
            .catch(error => {
                flash(error.response.data.detail, 'error');
            })
        ;
    }
}