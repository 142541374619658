import Vue from 'vue';
import axios from "axios";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {addDays, endOfDay, format, parseISO, startOfDay, startOfToday, subDays} from "date-fns";

Vue.component('booking', {
    template: '#booking',
    delimiters: ['[[', ']]'],
    props: {
        range: {
            type: Boolean,
            required: false,
            default: true
        },
        format: {
            type: String,
            required: false,
            default: 'YYYY-MM-DD'
        },
        notBefore: {
            type: Date,
            required: false,
        },
        isUpdate: {
            type: Boolean,
            required: false,
        },
        bookingsProp: {
            type: String,
            required: false,
        },
        selectedStartDate: {
            type: String,
            required: false,
        },
        selectedEndDate: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            dateRange: {
                start: null,
                end: null,
            },
            today: new Date(),
            innerValue: [new Date(NaN), new Date(NaN)],
        };
    },
    created() {
    },
    mounted() {
        this.moveDatePickerToCustomContainer();
        if(this.selectedStartDate != '' && this.selectedEndDate != ''){
            const [dayStart, monthStart, yearStart] = this.selectedStartDate.split('-');
            const [dayEnd, monthEnd, yearEnd] = this.selectedEndDate.split('-');

            this.dateRange = [
                new Date(yearStart, monthStart - 1, dayStart),
                new Date(yearEnd, monthEnd - 1, dayEnd)
            ]
        }
    },
    watch: {
        dateRange(newVal) {
            for (const range of this.bookings) {
                const existingStart = new Date(range.startDate);
                const existingEnd = new Date(range.endDate);
                const selectedStart = new Date(newVal[0]);
                const selectedEnd = new Date(newVal[1]);

                if (
                    (selectedStart >= existingStart && selectedStart <= existingEnd) ||
                    (selectedEnd >= existingStart && selectedEnd <= existingEnd) ||
                    (selectedStart <= existingStart && selectedEnd >= existingEnd)
                ) {
                    this.overlapError = 'Selected date range overlaps with an existing range';
                    this.dateRange = []; // Clear the selected range
                    return;
                }
            }
            this.overlapError = '';
        }
    },
    methods: {
        dateRangeChanged(dateRange) {
            document.getElementById('booking_startDate').value = this.formatDate(dateRange[0]);
            document.getElementById('booking_endDate').value = this.formatDate(dateRange[1]);
        },
        getClasses(cellDate, currentDates, classes) {
            if (
                !/disabled|active|not-current-month/.test(classes) &&
                currentDates.length === 2 &&
                cellDate.getTime() > currentDates[0].getTime() &&
                cellDate.getTime() < currentDates[1].getTime()
            ) {
                return "in-range";
            }
            return "";
        },
        isDateDisabled(date) {
            const dateString = date.toISOString().split('T')[0];
            return this.disabledDatesSet.has(dateString);
        },
        todayDate() {
            return startOfToday();
        },
        formatDate(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${year}-${month}-${day}`;
        },
        moveDatePickerToCustomContainer() {
            const datePickerElement = document.querySelector('.mx-datepicker-main');
            const customContainer = document.getElementById('datepicker-container');

            if (datePickerElement && customContainer) {
                customContainer.appendChild(datePickerElement);
            }
        },
    },
    computed: {
        bookings() {
            try {
                return JSON.parse(this.bookingsProp);
            } catch (error) {
                console.error('Error parsing JSON:', error);
                return [];
            }
        },
        disabledDatesSet() {
            const disabledDates = new Set();
            this.bookings.forEach(booking => {
                let startDate = subDays(startOfDay(parseISO(booking.startDate)), 1);
                const endDate = subDays(endOfDay(parseISO(booking.endDate)), 1);
                let currentDate = startDate;
                while (currentDate <= endDate) {
                    disabledDates.add(format(currentDate, 'yyyy-MM-dd'));
                    currentDate = addDays(currentDate, 1);
                }
            });
            return disabledDates;
        },
        disabledDates() {
            return date => {
                const currentDate = new Date(date);
                // if(this.isUpdate){
                //     const [dayStart, monthStart, yearStart] = this.selectedStartDate.split('-');
                //     let startDate = new Date(yearStart, monthStart - 1, dayStart);
                //
                //     const [dayEnd, monthEnd, yearEnd] = this.selectedEndDate.split('-');
                //     let endDate = new Date(yearEnd, monthEnd - 1, dayEnd);
                //
                //     // return startDate < currentDate || this.isDateDisabled(currentDate);
                //     return currentDate < startDate || endDate < currentDate || this.isDateDisabled(currentDate);
                // }else {
                    return currentDate < this.today || this.isDateDisabled(currentDate);
                // }
            };
        }
    },
    components: {
        DatePicker
    }
});