import Vue from 'vue';
import axios from "axios";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {parseISO, addDays, subDays, format, startOfDay, endOfDay, startOfToday} from 'date-fns';

Vue.component('calendar', {
    template: '#calendar',
    delimiters: ['[[', ']]'],
    props: {
        range: {
            type: Boolean,
            required: false,
            default: true
        },
        format: {
            type: String,
            required: false,
            default: 'YYYY-MM-DD'
        },
        notBefore: {
            type: Date,
            required: false,
        },
        formHtml: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dateRange: {
                start: null,
                end: null,
            },
            bookings: [],
            today: new Date(),
            innerValue: [new Date(NaN), new Date(NaN)],
            popupVisible: false,
            popupShow: false,
            popupMessage: '',
            isError: 'text-danger',
            disableButton: true,
        };
    },
    created() {
    },
    mounted() {
        this.getBookings();
        this.moveDatePickerToCustomContainer();
        console.log('daterange:', this.dateRange)
    },
    watch: {
        // dateRange(newVal) {
        //     this.$emit('date-range-changed', newVal);
        //     this.disableButton = false;
        //     // this.sendDateRangeToBackend(newVal);
        // }
        dateRange(newVal) {
            for (const range of this.bookings) {
                const existingStart = new Date(range.startDate);
                const existingEnd = new Date(range.endDate);
                const selectedStart = new Date(newVal[0]);
                const selectedEnd = new Date(newVal[1]);

                if (
                    (selectedStart >= existingStart && selectedStart <= existingEnd) ||
                    (selectedEnd >= existingStart && selectedEnd <= existingEnd) ||
                    (selectedStart <= existingStart && selectedEnd >= existingEnd)
                ) {
                    this.overlapError = 'Selected date range overlaps with an existing range';
                    this.dateRange = []; // Clear the selected range
                    return;
                }
            }

            this.disableButton = false;
            this.overlapError = '';
        }
    },
    methods: {
        hidePopup(){
          this.popupShow = false
            var _self = this;
            setTimeout(() => {
                _self.popupVisible = false
            }, 200);
        },
        handleSubmit() {
            const formElement = document.querySelector('form');
            const formData = new FormData(formElement);
            const formObject = {};

            let _self = this;
            formData.forEach((value, key) => {
                formObject[key] = value;
            });

            formObject.startDate = this.formatDate(this.dateRange[0]);
            formObject.endDate = this.formatDate(this.dateRange[1]);

            axios.post('/api/create-booking', formObject, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    _self.isError = false;
                    this.popupMessage = 'Form submitted successfully!';
                    flash('Form submitted successfully!', 'success');
                    console.log('Form submitted:', response.data);
                    _self.hidePopup();
                    _self.getBookings();
                })
                .catch(error => {
                    this.popupMessage = error;
                    _self.isError = true;
                    flash('Error submitting form: ' + error, 'error');
                    console.error('Error submitting form:', error);
                    _self.getBookings();
                });
        },
        formatDate(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${year}-${month}-${day}`;
        },
        formatDatePreview(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },
        async getBookings(){
            let url = '/api/bookings',
                _self = this;

            axios.get(url).then(response => {
                _self.bookings = response.data;
            }).catch(error => {
                console.error('Error getting bookings:', error);
            });
        },
        moveDatePickerToCustomContainer() {
            const datePickerElement = document.querySelector('.mx-datepicker-main');
            const customContainer = document.getElementById('datepicker-container');

            if (datePickerElement && customContainer) {
                customContainer.appendChild(datePickerElement);
            }
        },
        sendInquiry(){
            this.popupVisible = true;
            var _self = this;
            setTimeout(() => {
                _self.popupShow = true
            }, 100);

        },
        getClasses(cellDate, currentDates, classes) {
            if (
                !/disabled|active|not-current-month/.test(classes) &&
                currentDates.length === 2 &&
                cellDate.getTime() > currentDates[0].getTime() &&
                cellDate.getTime() < currentDates[1].getTime()
            ) {
                return "in-range";
            }
            return "";
        },
        isDateDisabled(date) {
            const dateString = date.toISOString().split('T')[0];
            return this.disabledDatesSet.has(dateString);
        },
        todayDate() {
            return startOfToday();
        },

    },
    computed: {
        formattedFormHtml() {
            return this.formHtml
                .replace(/<div class="mb-3">/g, '<div class="form-group mb-3">');
        },
        disabledDatesSet() {
            if(this.bookings) {
                const disabledDates = new Set();
                this.bookings.forEach(booking => {
                    const startDate = subDays(startOfDay(parseISO(booking.startDate)), 1);
                    const endDate = subDays(endOfDay(parseISO(booking.endDate)), 1);
                    let currentDate = startDate;
                    while (currentDate <= endDate) {
                        disabledDates.add(format(currentDate, 'yyyy-MM-dd'));
                        currentDate = addDays(currentDate, 1);
                    }
                });
                return disabledDates;
            }

            return null;
        },
        disabledDates() {
            return date => {
                const currentDate = new Date(date);
                return currentDate < this.today || this.isDateDisabled(currentDate);
            };
        },
        differenceInDays() {
            if (this.dateRange[0] && this.dateRange[1]) {
                const date1 = new Date(this.dateRange[0]);
                const date2 = new Date(this.dateRange[1]);

                // Calculate difference in milliseconds
                const diffInMs = Math.abs(date2.getTime() - date1.getTime());

                // Convert milliseconds to days
                return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
            } else {
                return '';
            }
        }
    },
    components: {
        DatePicker
    }
});
