import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    connect(){
        EventManager.listen('master-field-changed', data => {
            if (data.propertyName === this.propertyName) {
                if (parseInt(data.value) >= this.filesCount) {
                    this.element.classList.remove('d-none');
                } else {
                    this.element.classList.add('d-none');
                }
            }
        })
    }

    get propertyName() {
        return this.element.dataset.propertyName;
    }

    get filesCount() {
        return parseInt(this.element.dataset.filesCount);
    }
}