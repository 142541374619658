import {Controller} from "@hotwired/stimulus";
import axios from "axios";
import {Modal} from "bootstrap";

export default class extends Controller {
    static targets = ['inputElement'];

    connect() {
        EventManager.listen('image-gallery.select-image', payload => {
            const inputElement = this.inputElementTarget;
            const identifier = inputElement.getAttribute('id');

            if (payload.identifier === identifier) {
                inputElement.value = payload.path;
                this.closeGallery();
            }
        });
    }

    async showImageGallery() {
        const identifier = this.element.querySelector('input').getAttribute('id');

        this.element.innerHTML += await axios.get('/admin/image-gallery-component/' + identifier).then(response => response.data);

        this.modal = new Modal(this.element.querySelector('#image-gallery-modal'));
        this.element.addEventListener('hidden.bs.modal', this.closeGallery.bind(this));
        this.modal.show();
    }

    async search(event) {
        this.element.querySelector('#image-browse-list').innerHTML = await axios.get('/admin/image-gallery-component/images', {params: {name: event.target.value}}).then(response => response.data);
    }

    closeGallery() {
        this.modal.hide();
        let turboFrame = this.element.querySelector('#image-gallery-frame');
        turboFrame.remove();
    }
}