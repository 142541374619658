import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['arrow', 'answer'];

    connect() {
        if (this.shouldBeOpened) {
            this.showAnswer();

            this.element.scrollIntoView({behavior: "smooth", block: "center"});
        } else {
            this.hideAnswer();
        }

        EventManager.listen('question-opened', data => {
            if (this.questionid !== data.questionId) {
                this.hideAnswer();
            }
        });
    }

    toggleAnswer() {
        if (this.isOpened === false) {
            this.showAnswer();
        } else {
            this.hideAnswer();
        }
    }

    showAnswer() {
        this.arrowTarget.classList.remove('fa-angle-right');
        this.arrowTarget.classList.add('fa-angle-down');
        this.answerTarget.classList.remove('not-visible');
        this.setIsOpened('true');

        EventManager.fire('question-opened', {questionId: this.questionid});
    }

    hideAnswer() {
        this.arrowTarget.classList.add('fa-angle-right');
        this.arrowTarget.classList.remove('fa-angle-down');
        this.answerTarget.classList.add('not-visible');
        this.setIsOpened('false');
    }

    get shouldBeOpened() {
        return this.element.dataset.shouldBeOpened === 'true';
    }

    get isOpened() {
        return this.element.dataset.opened === 'true';
    }

    setIsOpened(value) {
        this.element.dataset.opened = value;
    }

    get questionid() {
        return this.element.dataset.questionId;
    }
}