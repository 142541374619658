import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    connect() {
        let _self = this;

        Sortable.create(this.element, {
            ghostClass: 'blue-background-during-drag',
            animation: 300,
            onSort: function () {
                let inputs = _self.element.querySelectorAll('input');

                inputs.forEach((element, index) => {
                    element.setAttribute('id', _self.updateId(element.getAttribute('id'), index));
                    element.setAttribute('name', _self.updateName(element.getAttribute('name'), index));
                })
            }
        });
    }

    updateName(currentName, index) {
        let position = index % 2 === 0 ? index / 2 : (index - 1) / 2;

        return currentName.replace(/\[\d+\]/, `[${position}]`);
    }

    updateId(currentId, index) {
        let position = index % 2 === 0 ? index / 2 : (index - 1) / 2;

        return currentId.replace(/_\d+_/, `_${position}_`);
    }
}