import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['arrow', 'content'];
    static values = {
        title: String
    }

    connect() {
        if(this.titleValue){
            let currentUrl = window.location.href,
                parts = currentUrl.split('#'),
                fragment = null;

            if (parts.length > 1) {
                fragment = decodeURI(parts[parts.length - 1]).toLowerCase().replace(/ /g, '-');
                if(fragment === this.titleValue.toLocaleLowerCase().replace(/ /g, '-')){
                    this.toggleContent();
                    this.element.scrollIntoView({behavior: "smooth", block: "nearest"});
                }
            }
        }
    }

    toggleContent() {
        this.arrowTarget.classList.toggle('fa-angle-right');
        this.arrowTarget.classList.toggle('fa-angle-down');

        this.contentTarget.classList.toggle('not-visible');
    }
}