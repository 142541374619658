import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        window.EventManager.listen(
            'flash', messageData => this.addMessage(messageData)
        );
    }

    addMessage(messageData) {
        const container = document.createElement("div");
        const message = document.createTextNode(messageData.message);

        container.className = 'flash-message alert alert-' + this.getMessageClass(messageData.level);
        container.dataset.controller = 'flash-message';
        container.appendChild(message);
        this.element.appendChild(container);
    }

    getMessageClass(level) {
        if (level === 'error') {
            return 'danger';
        } else {
            return 'success';
        }
    }
}