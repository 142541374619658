import {Controller} from "@hotwired/stimulus";
import axios from "axios";
import {visit} from "@hotwired/turbo";

export default class extends Controller {
    static values = {
        uploadUrl: String,
        redirectUrl: String
    }

    static targets = ['fileInput', 'button', 'icon'];

    selectFile() {
        this.fileInputTarget.click();
    }

    uploadFile() {
        let file = this.fileInputTarget.files[0],
            _self = this;

        if (!file) {
            return;
        }

        let formData = new FormData();
        formData.append("file", file);

        this.setUploadingState(true);

        axios.post(this.uploadUrlValue, formData,{
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(response => {
                visit(_self.redirectUrlValue);
            })
            .catch(error => {
                flash(error.response.data.detail, 'error');
                this.setUploadingState(false);
            })
        ;
    }

    setUploadingState(value) {
        if (value === true) {
            this.buttonTarget.setAttribute('disabled', 'disabled');
            this.iconTarget.classList.toggle('fa-upload');
            this.iconTarget.classList.add('fa-spinner');
            this.iconTarget.classList.add('fa-pulse');
        } else {
            this.buttonTarget.removeAttribute('disabled');
            this.iconTarget.classList.toggle('fa-upload');
            this.iconTarget.classList.remove('fa-spinner');
            this.iconTarget.classList.remove('fa-pulse');
        }
    }
}