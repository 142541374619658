import Vue from 'vue';

Vue.component('booking-modal', {
    template: '#bookingModal',
    delimiters: ['[[', ']]'],
    props: {
        showModal: {
            type: Boolean,
            required: false
        },
        message: {
            type: String,
            required: false
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
});