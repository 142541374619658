import Vue from 'vue';

Vue.component('modal', {
    template: '#modal',
    delimiters: ['[[', ']]'],
    props: {
        title: {
            type: String,
            required: false,
            default: 'Default modal title'
        },
        primaryButton: {
            type: String,
            required: false,
            default: 'Save'
        },
        identifier: {
            type: String,
            required: true
        },
        size: {
            type: String,
            required: false,
            default: null
        },
        verticallyCentered: {
            type: Boolean,
            required: false,
            default: false
        },
        hasFooter: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    computed: {
        sizeClass() {
            if (!this.size) {
                return '';
            }

            return `modal-${this.size}`;
        },
        verticallyCenteredClass() {
            if (this.verticallyCentered) {
                return 'modal-dialog-centered';
            } else {
                return '';
            }
        }
    }
});