import {Controller} from "@hotwired/stimulus"
import axios from 'axios';

export default class extends Controller {
    static values = {
        locale: String
    }

    switch(event) {
        let url = `/admin/locale/${this.localeValue}`;

        event.preventDefault();

        axios.post(url)
            .then(() => window.location.reload())
            .catch(error => console.log(error))
        ;
    }
}