// assets/controllers/nav_controller.js

import {Controller} from "@hotwired/stimulus";
export default class extends Controller {
    connect() {

        var navToggle = this.element
        if (navToggle) {
            navToggle.addEventListener("click", function (e) {
                this.classList.toggle('active');
                document.body.classList.toggle('nav-open');
                e.preventDefault();
            });
        }

        document.querySelectorAll('.link-sub-menu .link-arrow').forEach(function (element) {
            element.addEventListener('click', function (e) {
                this.parentElement.classList.toggle('sub-menu-open');
                e.preventDefault();
            });
        });
    }
}
