import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        flashes: String
    }

    connect() {
        let flashesObject = JSON.parse(this.flashesValue);

        if (flashesObject.length === 0) {
            return;
        }
        
        for (let key in flashesObject) {
            flashesObject[key].forEach(message => {
                flash(message, key);
            });
        }
    }
}