import {Controller} from '@hotwired/stimulus';
import axios from "axios";
import {visit} from "@hotwired/turbo";

export default class extends Controller {
    static values = {
        confirmationText: String,
        postUrl: String,
        redirectUrl: String
    }

    onClick(event) {
        event.preventDefault();

        const shouldProceed = confirm(this.confirmationTextValue);

        if (shouldProceed) {
            axios.post(this.postUrlValue)
                .then(() => visit(this.redirectUrlValue));
        }
    }
}
