// assets/controllers/swiper_controller.js

import {Controller} from "@hotwired/stimulus";
import Swiper from "swiper";
import 'swiper/swiper-bundle.css';
import {Navigation, Pagination} from "swiper/modules";


export default class extends Controller {
    connect() {


        Swiper.use([Navigation, Pagination]);

        this.swiper = new Swiper(this.element, {
            slidesPerView: 2,
            spaceBetween: 20,
            pagination: {
                el: ".swiper-pagination",
                //type: 'bullets'
                clickable: true,
            },
        });
    }
}
