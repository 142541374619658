import { Controller } from "@hotwired/stimulus"

export default class extends Controller {s
    static values = {
        class: String
    }

    connect() {
        if (window.location.pathname === this.element.getAttribute('href')) {
            this.element.classList.add('fw-bolder');

            if (this.hasClassValue) {
                this.element.classList.add(this.classValue);
            }
        }
    }
}