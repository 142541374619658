import { Controller } from "@hotwired/stimulus";
import imagesLoaded from "imagesloaded";
import Isotope from "isotope-layout";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default class extends Controller {
    static targets = ["grid", "button"]

    connect() {
        this.initializeIsotope()

        Fancybox.bind('[data-fancybox="gallery"]');
    }

    initializeIsotope() {
        const grid = this.gridTarget

        imagesLoaded(grid, () => {
            this.iso = new Isotope(grid, {
                itemSelector: '.grid-item',
                layoutMode: 'masonry',
                masonry: {
                    columnWidth: '.grid-item',
                    percentPosition: true
                },
                transitionDuration: '0.6s'
            })

            this.buttonTargets.forEach(button => {
                button.addEventListener('click', this.filterItems.bind(this))
            })
        })
    }

    filterItems(event) {
        const filterValue = event.target.getAttribute('data-filter')
        // Remove the "active" class from all buttons
        this.buttonTargets.forEach(button => button.classList.remove('active'))

        // Add the "active" class to the clicked button
        event.target.classList.add('active');
        this.iso.arrange({ filter: filterValue })
    }
}
