import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.element.addEventListener('change', () => {
            EventManager.fire('master-field-changed', {
                propertyName: this.propertyName,
                value: this.element.value
            })
        });

        $(document).ready(() => {
            EventManager.fire('master-field-changed', {
                propertyName: this.propertyName,
                value: this.element.value
            })
        });
    }

    get propertyName() {
        return this.element.dataset.propertyName;
    }
}